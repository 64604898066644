.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Changed from 'top' to 'flex-start' for valid value */
  min-height: 100vh; /* Ensure it takes at least the full height of the viewport */
  padding-top: 100px; /* Adjust based on the actual height of your header */
  padding-bottom: 200px; /* Adjust to ensure there's space at the bottom */
}

.centeredContent {
  text-align: center;
  font-size: 20px;
  color: #424242;
  width: 90%; /* Set width to be 90% of the screen */
  max-width: 600px; /* Maximum width is 600px */
  margin: 0 auto; /* Centers the content horizontally */
}

@media (max-width: 600px) {

  .App {
    padding-top: 50px; /* Reduce padding for smaller screens */
    padding-bottom: 100px; /* Reduce padding at the bottom */
  }

  .centeredContent {
    font-size: 16px; /* Smaller font size for mobile devices */
    width: 95%;
  }

  .accountTile .accountBalance .accountInfo {
    font-size: 16px; /* Smaller font size for mobile devices */
  }

  .dashboardPaper p, .dashboardPaper .MuiTypography-root {
    font-size: 16px !important;
  }

  .dashboardPaper2 p, .dashboardPaper2 {
    font-size: 12px !important;
  }
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

img, video {
  max-width: 100%;
  height: auto;
}

.accountTile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  gap: 20px;
  background-color: #f5f5f5;
  cursor: pointer; /* Changes the cursor on hover to indicate it's clickable */
  transition: background-color 0.3s ease; /* Smooth transition for the background color change */
  margin: 10px 0 0
}

.accountTile:hover {
  background-color: rgba(255, 224, 178, 0.8); /* Light orange background on hover */
}

.accountInfo {
  flex: 1; /* Allows the description and interest rate section to grow */
  text-align: left;
}

.accountBalance {
  flex: 0 1 auto; /* Allows the balance section to shrink but not grow, maintaining its content's width */
  text-align: right; /* Aligns the balance to the right */
}
