.header {
    width: 100%; /* Ensure full width */
    background-color: rgba(238, 238, 238, 0.92);
    color: #616161;
    text-align: left;
    position: fixed; /* or 'fixed' if you want it always visible */
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; /* Adjust as needed */
    box-sizing: border-box; /* Ensures padding doesn't affect the overall width */
    z-index: 100; 
}


.headerText {
    font-size: 1.25rem; /* Adjust this value as needed to match your button text size */
    display: flex;
    align-items: center; /* This helps vertically center your text if the header uses flex */
}

@media (max-width: 600px) {
  
    .headerText {
      font-size: 16px; /* Smaller font size for mobile devices */
    }

    .header button, .header .MuiSelect-select {
        font-size: 11px !important;
      }
  }


